<template>
  <v-card tile :class="tileClass" @click="cardClick()" :ripple="hasLink">
    <v-card-title>{{ title }}</v-card-title>
    <v-system-bar color="primary" height="4"></v-system-bar>
    <v-card-text class="d-flex align-center justify-center py-12">
      <div class="text-h3">
        <slot></slot>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import * as disp from "@/utility/display.js";

// @ is an alias to /src
export default {
  name: "DashboardCard",
  data: () => ({}),
  props: {
    title: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  computed: {
    tileClass() {
      if (this.hasLink) {
        return "selectable";
      } else {
        return "not-selectable";
      }
    },
    hasLink() {
      return !disp.IsNullorWhitespace(this.link);
    }
  },
  methods: {
    cardClick() {
      if (this.hasLink) {
        this.nav(this.link);
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  watch: {},
};
</script>

<style scoped>
.selectable {
  cursor: pointer;
}

.not-selectable {
  cursor: default;
}
</style>