<template>
  <div class="fill-height">
    <v-container fluid class="container-main">
      <v-row justify="start">
        <v-overlay v-model="loading" absolute>
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </v-overlay>
        <v-col cols="12" class="pb-1">
          <h2>{{ envName }} Dashboard</h2>
          <p class="text-body-1 font-italic text--secondary mb-0">
            Your status at a glance
          </p>
        </v-col>
        <v-col cols="12" class="h-100">
          <v-system-bar color="accent" height="5"></v-system-bar>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <dashboard-card
            title="Resumes Pending Review"
            link="/ActiveReviewList"
          >
            {{ reviewCount }}
          </dashboard-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <dashboard-card title="Total Users" link="/AllUsers">
            {{ activeUserCount }}
          </dashboard-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <dashboard-card title="Admin Users" link="/AdminUsers">
            {{ adminUserCount }}
          </dashboard-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Env } from "../../environment/EnvironmentFactory";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import DashboardCard from "../../components/DashboardCard.vue";

// @ is an alias to /src
export default {
  name: "CareerDashboard",
  data: () => ({
    loading: true,
  }),
  components: {
    "dashboard-card": DashboardCard,
  },
  async mounted() {
    try {
      await this.loadActiveReviews();
      await this.loadUsers();
      await this.loadEnvironment();
    } finally {
      this.loading = false;
    }
  },
  watch: {},
  computed: {
    envName() {
      return Env().GetEnvName();
    },
    reviewCount() {
      if (
        this.activeReviews === undefined ||
        this.activeReviews === null ||
        this.loading
      ) {
        return "-";
      }
      return this.activeReviews.length;
    },
    activeUserCount() {
      if (this.users === undefined || this.users === null || this.loading) {
        return "-";
      }
      return this.users.length;
    },
    adminUserCount() {
      if (
        this.adminUsers === undefined ||
        this.adminUsers === null ||
        this.loading
      ) {
        return "-";
      }
      return this.adminUsers.length;
    },
    ...mapFields("environment", ["activeReviews", "users", "adminUsers"]),
  },
  methods: {
    ...mapActions("environment", [
      "loadActiveReviews",
      "loadUsers",
      "loadEnvironment",
    ]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
};
</script>

<style>
</style>